<template>
  <div class="member-add">
    <Header class="mb-12" type="book"></Header>
    <Form ref="form" :rows="rows" v-model="detail"> </Form>
    <TextArea
      v-model="remark"
      class="textarea mb-12"
      label="备注"
      :showLimit="false"
      :required="false"
    />
    <FooterButton :loading="loading" @click="submit">提交申请</FooterButton>
  </div>
</template>
<script>
import Header from "@/components/header";
import FooterButton from "@/components/common/button/FooterButton.vue";
import Form from "@/components/common/form/index.vue";
import { shidanliBusinessApi } from "@/utils/api.js";
import TextArea from "@/components/common/textArea/index.vue";
import { getDealerName } from "@/utils/user.js";
export default {
  components: {
    Header,
    FooterButton,
    Form,
    TextArea,
  },
  data() {
    return {
      loading: false,
      detail: { userName: getDealerName() },
      remark: "",
      rows: [
        {
          key: "nickName",
          label: "姓名",
          type: "input",
          required: true,
        },
        {
          key: "sex",
          label: "性别",
          type: "radio",
          data: [
            { key: "1", value: "男" },
            { key: "0", value: "女" },
          ],
          required: true,
        },
        {
          key: "phonenumber",
          label: "手机号",
          type: "input",
          inputType: "tel",
          required: true,
        },
        { key: "userName", label: "经销商" },
      ],
    };
  },
  activated() {
    this.detail = { userName: getDealerName() };
  },
  methods: {
    async submit() {
      if (!this.$refs.form.validate()) {
        return;
      }
      this.loading = true;
      const { phonenumber, sex, nickName } = this.detail;
      try {
        await shidanliBusinessApi.salemanAdd({
          remark: this.remark,
          nickName,
          sex,
          phonenumber,
        });
        this.$toast("新增成功");
        this.$router.back();
      } catch (err) {
        this.$toast(err.message);
      }
      this.loading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.member-add {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
</style>
