<template>
  <div class="shidanli-business-terminals">
    <HeaderNavigation label="确定" icon="" path="back" />
    <div class="search-wrap">
      <SearchWrap v-model="searchTotal" @search="resetList"></SearchWrap>
    </div>
    <div class="list-wrap">
      <CustomCard
        v-for="(item, index) in list"
        :key="index"
        :title="''"
        :content="formatCardContent(item)"
      >
        <div class="action-wrap">
          <CustomButton size="mini" @click="navigateDetailPage(item.id)"
            >查看销量</CustomButton
          >
        </div>
      </CustomCard>
    </div>
    <infinite-loading ref="infinite" @infinite="infiniteHandler">
      <span slot="no-more">
        暂无更多
      </span>
    </infinite-loading>
  </div>
</template>
<script>
import SearchWrap from "@/components/common/search";
import CustomCard from "@/components/common/card";
import CustomButton from "@/components/common/button/CustomButton.vue";
import HeaderNavigation from "@/components/header/headerWithNavigation";
import InfiniteLoading from "vue-infinite-loading";
import { terminalOutletsApi } from "@/utils/api.js";
export default {
  components: {
    SearchWrap,
    CustomCard,
    CustomButton,
    HeaderNavigation,
    InfiniteLoading,
  },
  data() {
    return {
      activeName: "",
      searchTotal: "",
      list: [],
      pageNo: 1,
      pageSize: 5,
      total: 0,
    };
  },
  mounted() {
    this.activeName = this.$route.query.type || "shidanli";
  },
  methods: {
    // 搜索
    resetList() {
      this.showFilter = false;
      this.$refs.infinite._events["$InfiniteLoading:reset"][0]();
      this.list = [];
      this.pageNo = 1;
      this.total = 0;
    },
    async infiniteHandler($state) {
      try {
        await this.search();
      } catch (err) {
        //
      }
      $state.loaded();
      this.pageNo += 1;
      if (this.total - this.list.length === 0) {
        $state.complete();
      }
    },
    async search(clear) {
      if (clear) {
        this.resetList();
      }
      let response = {};
      if (this.activeName === "shidanli") {
        response = await terminalOutletsApi.list({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          coordinate: "",
          searchTotal: this.searchTotal,
          "page.orderBy": "distance",
        });
      } else {
        response = await terminalOutletsApi.distList({
          "page.pageNo": this.pageNo,
          "page.pageSize": this.pageSize,
          coordinate: "",
          searchTotal: this.searchTotal,
          "page.orderBy": "distance",
        });
      }
      this.list = this.list.concat(response.data || []);
      this.total = (response.page && response.page.total) || 0;
    },
    formatCardContent(item) {
      return [
        { label: "详细地址", value: item.location },
        { label: "上次发货日期", value: item.deliveryDate },
      ];
    },
    navigateDetailPage(id) {
      this.$router.push({
        path: `/sales/terminalSales?type=terminalOutlets&&id=${id}`,
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.shidanli-business-terminals {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  .search-wrap {
    padding: 10px 24px 10px 12px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .sort-wrap {
      margin-left: 32px;
    }
  }
  .list-wrap {
    padding: 12px;
    .action-wrap {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .label {
        font-size: 12px;
        color: #2c2c2c;
        .required {
          color: #cd001f;
          margin-right: 4px;
        }
      }
    }
  }
}
</style>
