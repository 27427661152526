<template>
  <div>
    <Header />
    <div class="img-wrap">
      <img :src="detail.largerUrl" alt="" />
    </div>
    <div class="detail-wrap">
      <div class="title">{{ detail.materialName }}</div>
      <!-- <div>
        <span class="label">产品描述</span>
        <span class="value">{{ detail.materialDesc }}</span>
      </div>
      <div>
        <span class="label">产品品牌</span>
        <span class="value">{{ detail.brandName }}</span>
      </div>
      <div>
        <span class="label">产品配比</span>
        <span class="value">{{ detail.matchEq }}</span>
      </div>
      <div>
        <span class="label">产品工艺</span>
        <span class="value">{{ detail.materialContent }}</span>
      </div>
      <div>
        <span class="label">包重</span>
        <span class="value">{{ detail.bagWeightName }}</span>
      </div>
      <div>
        <span class="label">产品知识</span>
        <span class="value">{{ detail.materialClass }}</span>
      </div> -->
      <!--  -->
      <div>
        <span class="label">产品品牌</span>
        <span class="value">{{ detail.brandName }}</span>
      </div>
      <div>
        <span class="label">产品配比</span>
        <span class="value">{{ detail.matchEq }}</span>
      </div>
      <div>
        <span class="label">物料分类</span>
        <span class="value">{{ detail.materialClassName }}</span>
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header/index.vue";
export default {
  components: { Header },
  data() {
    return {
      detail: {},
    };
  },
  activated() {
    try {
      this.detail = JSON.parse(window.localStorage.getItem("KnowledgeDetail"));
    } catch (err) {
      //
    }
  },
};
</script>
<style lang="scss" scoped>
.img-wrap {
  width: 100%;
  padding: 8px;
  border: 1px solid #e4e4e4;
  text-align: center;
  box-sizing: border-box;
  img {
    width: 100%;
    height: auto;
  }
}
.detail-wrap {
  padding: 24px;
  font-size: 14px;
  color: #2c2c2c;
  text-align: left;
  & > div {
    margin-bottom: 6px;
  }
  .title {
    font-weight: 500;
    margin-bottom: 10px;
  }
  .label {
    color: #818181;
  }
  .value {
    margin-left: 6px;
  }
}
</style>
