<template>
  <div class="knowledge-filter">
    <Header class="mb-12" />
    <span class="confirm-button" @click="confirm">确定</span>
    <div v-show="mode === '0'" class="knowledge-index">
      <Form :rows="rows" v-model="value" />
    </div>
    <div v-show="mode === '1'" class="knowledge-brand">
      <div class="search-container">
        <Search class="mb-12" v-model="searchTotal" />
      </div>
      <div
        v-for="(item, index) in brands"
        :key="index"
        class="picker-item"
        :class="{ active: item.dictValue === value.brandId }"
        @click="onClick('brandId', item.dictValue, 'brandName', item.dictLabel)"
      >
        {{ item.dictLabel }}
      </div>
    </div>
    <div v-show="mode === '2'" class="knowledge-kind">
      <div class="search-container">
        <Search class="mb-12" v-model="searchTotal" />
      </div>
      <div
        v-for="(item, index) in materialClasses"
        :key="index"
        class="picker-item"
        :class="{ active: item.dictValue === value.materialClass }"
        @click="
          onClick(
            'materialClass',
            item.dictValue,
            'materialName',
            item.dictLabel
          )
        "
      >
        {{ item.dictLabel }}
      </div>
    </div>
  </div>
</template>
<script>
import Header from "@/components/header";
import Form from "@/components/common/form/index.vue";
import { commonApi } from "@/utils/api.js";
import Search from "@/components/common/search/index.vue";
export default {
  components: {
    Form,
    Header,
    Search,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      mode: "0",
      searchTotal: "",
      rows: [
        {
          key: "brandName",
          label: "品牌",
          type: "action",
          handleAction: () => {
            this.mode = "1";
          },
        },
        {
          key: "materialName",
          label: "分类",
          type: "action",
          handleAction: () => {
            this.mode = "2";
          },
        },
      ],
      brands: [],
      materialClasses: [],
    };
  },
  async mounted() {
    let response = await commonApi.dictList({
      type: "eb_material_class",
    });
    this.materialClasses = response.data;
    response = await commonApi.dictList({
      type: "crm_compete_brand",
    });
    this.brands = response.data;
  },
  methods: {
    confirm() {
      if (this.mode !== "0") {
        this.mode = "0";
        this.searchTotal = "";
      } else {
        this.$emit("confirm");
      }
    },
    onClick(key, value, labelKey, label) {
      if (labelKey && label) {
        this.$emit("input", { ...this.value, [key]: value, [labelKey]: label });
      } else {
        this.$emit("input", { ...this.value, [key]: value });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.knowledge-filter {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
  position: relative;
  .confirm-button {
    position: absolute;
    top: 12px;
    right: 24px;
    color: #ffffff;
    font-size: 14px;
  }
  .search-container {
    padding: 12px 24px;
  }
  .picker-item {
    padding: 12px 24px;
    text-align: left;
    &.active {
      background-color: #cd001f;
      color: #ffffff;
    }
  }
}
</style>
