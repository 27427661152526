<template>
  <div class="filter-page">
    <Header class="mb-12" />
    <SearchWrap v-model="searchTotal" @search="getSearch"></SearchWrap>
    <div class="item-tag">
          <span class="van-sku-row__item"
                v-for="(children) in itemList"
                :key="children.id"
                :class="{ active: children.ancestors === ancestors }"
                @click="tagClick(children.ancestors)"
          >
            <div class="van-sku-row__item-name">{{children.label}}</div>
          </span>
    </div>
  </div>
</template>
<script>
import {articleSearch} from '@/api/agro';
import Header from "@/components/header";
import SearchWrap from "@/components/common/search";
export default {
  components: {
    Header,
      SearchWrap
  },
  data() {
    return {
      itemList:[],
      ancestors:'',
      searchTotal:''
    };
  },
    mounted() {
        this.getSearch();
    },
  methods: {
      async getSearch(){
          const categoryName = this.searchTotal;
          const res = await articleSearch({categoryName});
          this.itemList = res.data;

      },
      tagClick(ancestors) {
          this.ancestors = ancestors;
          this.confirm(ancestors);
      },
    reset() {
      this.itemList = {};
      this.$emit("input", {});
    },
    confirm() {
        const ancestors = this.ancestors;
        this.$emit("input", {
            ancestors:ancestors
        });
        this.$emit("confirm");
    },
  },
};
</script>
<style lang="scss" scoped>
.filter-page {
  width: 100%;
  min-height: 100vh;
  background-color: #fafbfc;
}
.item-tag {
  height: 60px;
  text-align: left;

  tag {
    margin: 10px;
  }
}
</style>
